/* Light mode */
/* border */
.nav li a.current-link,
hr.line-dark {
    border-color: $darkgrey;
}

hr.line-bright,
.project-category-module:hover hr,
.line-education {
    border-color: $white;
}

.filter-button.all:not(.active) {
    border-color: $brokenwhite;
}

.nav li a:hover,
.owl-carousel .item,
.about-image img,
.download-button,
.filter-button.all.active,
.project-module {
    border-color: $orange;
}

/* Background */
.module-info {
    background: $black;
}

.bg-gray,
.project-category-module,
.project-button,
.skills-filter .filters .filter-button,
.skills-filter .filter-items .filter-item,
.education,
.download-button:hover,
.socials,
.owl-dots .owl-dot span,
.filter-button.all {
    background: $brokenwhite;
}

.custom-alert,
footer,
.project-button:hover,
.skills-filter .filters .filter-button.active:not(.all),
.work-container::before,
.work-container .sub-info::before,
.work-container:not(:last-child)::after,
.work-container .sub-info:not(:last-child)::after,
.work-sub::before,
.info-row .button,
.download-button,
.skills-filter .filter-items .filter-item.active {
    background: $orange;
}

.slider:before,
.line-education::before,
.line-education::after {
    background: $white;
}

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span,
.slider,
.slider::after,
input:checked+.slider:before {
    background: $grey;
}

.project-category-module::after,
.project-module::after,
header::after {
    background: rgba(0, 0, 0, 0.5);
}

/* Colors */
.nav li a,
.social-mail,
.project-category-module,
.project-category-module h2,
.project-category-module p,
.project-button,
.project-button:hover,
.work-container h4,
.work-container .work-time,
.info-row .info-container .icon:not(.modal-toggle),
header .mobile-toggle,
header .switch-text,
.skills-filter .filter-items .filter-item {
    color: $darkgrey;
}

.nav li a:hover,
.skills-filter .filters .filter-button,
.education h3,
.work-container h3,
.download-button:hover,
.info-row h3,
.close-image-modal,
.filter-button.all.active {
    color: $orange;
}

.custom-alert,
.custom-alert a,
.module-info,
.module-info:hover p,
.module-info:hover span,
.module-info:hover i,
.project-category-module:hover,
.project-category-module:hover h2,
.project-category-module:hover p,
.project-category-module .button,
.project-module .title,
.project-module:hover .module-info p,
.project-module:hover .module-info span,
.project-module:hover .module-info i,
.skills-filter .filters .filter-button.active:not(.all),
.info-row .button,
.download-button,
.skills-filter .filter-items .filter-item.active,
.module-info {
    color: $white;
}

/* Box shadows */
.shadow-image {
    box-shadow: 0 50px 100px rgb(0 0 0 / 30%);
}

.color-square {
    box-shadow: 0 7px 10px $brokenwhite;
}

/* Media */
@media (max-width: 767px) {
    header .nav-container {
        background: $brokenwhite;
        box-shadow: 0 0 30px rgb(0 0 0 / 30%);
    }

    header .nav-container .current-link {
        border-color: $darkgrey;
    }

    .project-category-module .text,
    .project-category-module p,
    .project-category-module h2 {
        color: $white;
    }

    .project-category-module hr {
        border-color: $white;
    }
}

/* Dark mode */
body.dark-mode {
    background: $darkgrey;

    p,
    li,
    .nav a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .typography-container,
    .education span,
    .info-row .info-container .icon,
    header .mobile-toggle,
    header .switch-text,
    .social-mail {
        color: $white;
    }

    .socials,
    .project-category-module,
    .bg-gray,
    footer,
    .education,
    header.menu-active .nav-container {
        background: $mediumdarkgrey;
    }

    .slider,
    .slider::after {
        background: $white;
    }

    hr.line-dark,
    .nav li a.current-link {
        border-color: $white;
    }

    .work-container .line-work {
        border-color: $lightgrey;
    }

    .work-container .work-time {
        color: $lightgrey;
    }

    .color-square {
        box-shadow: 0 7px 10px $black;
    }
}
