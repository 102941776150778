// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: 'Open Sans', Arial, sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.2;

$font-awesome: 'Font Awesome 5 Free';
$font-awesome-brands: 'Font Awesome 5 Brands';

$link-color: #FF6633;

$headings-font-family: 'europa', Arial, sans-serif !default;
$headings-font-weight: 800 !default;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #FF6633;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$white: #ffffff;
$brokenwhite: #E6E7E8;
$black: #000000;
$grey: #808080;
$lightgrey: #a0a0a0;
$mediumdarkgrey: #333333;
$darkgrey: #161616;

// Mixins
@mixin transition-custom($property) {
    transition: $property;
    -webkit-transition: $property;
    -moz-transition: $property;
    -ms-transition: $property;
    -o-transition: $property;
}

@mixin border-radius-custom($width) {
    border-radius: $width;
    -webkit-border-radius: $width;
    -moz-border-radius: $width;
    -ms-border-radius: $width;
    -o-border-radius: $width;
}

@mixin transform($property) {
    transform: $property;
    -webkit-transform: $property;
    -moz-transform: $property;
    -ms-transform: $property;
    -o-transform: $property;
}

@mixin displayFlexCenter($direction) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: $direction;
    flex-wrap: wrap;
    flex-flow: $direction wrap;
}

@mixin laptop {
    @media (max-width: 991px){
        @content;
    }
}

@mixin phone {
    @media (max-width: 575px) {
        @content;
    }
}