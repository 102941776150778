@media (max-width: 767px) {
    header .mobile-toggle {
        position: fixed;
        cursor: pointer;
        font-size: 20px;
        left: -8px;
        top: 20px;
        z-index: 9999;
        min-width: 50px;
    }

    header {
        .mobile-toggle span {
            font-size: 16px;
            position: absolute;
            white-space: nowrap;
            left: 30px;
            @include transition-custom(.3s);
        }

        &.scroll .mobile-toggle span {
            left: -50px;
        }

        &.menu-active .nav-container {
            left: 0;
        }

        &.menu-active .mobile-toggle {
            span {
                left: 30px;
            }

            i::before {
                content: '\f00d';
            }
        }

        .nav {
            display: block;
            margin-bottom: 30px;
        }

        li a {
            display: block;
            border-left: 2px solid transparent;
            padding: 6px 15px !important;
        }

        .nav-container {
            position: fixed;
            left: -100%;
            width: 60%;
            padding: 75px 20px 20px 20px;
            z-index: 999;
            box-shadow: 0 0 30px rgb(0 0 0 / 30%);
            z-index: 9998;

            display: flex;
            justify-content: space-between;
            flex-direction: column;
            overflow: auto;
            height: 100%;
            @include transition-custom(.3s);
        }

        .switch {
            margin: 0;
            position: absolute;
            right: 20px;
            top: 20px;
        }

        .switch-text {
            display: block;
            text-align: center;
            font-size: 12px;
            font-weight: bold;
        }

        &::after {
            content: '';
            width: 100vw;
            height: 100vh;
            position: fixed;
            z-index: -1;
            opacity: 0;
            @include transition-custom(.3s);
        }

        &.menu-active::after {
            opacity: 1;
            z-index: 9997;
        }
    }

    .socials {
        display: none;
    }

    .mobile-info-container {
        display: block;

        .social {
            display: block;
            margin: 10px 0;

            i {
                margin-right: 10px;
                min-width: 20px;
            }
        }

        h3 {
            font-size: 20px;
        }
    }

    body.menu-active {
        overflow: hidden;
    }

    .project-module .module-info {
        width: calc(100% + 20px);
    }

    .module-info {
        width: calc(100% + 40px);
        height: auto;
        white-space: normal;
        @include border-radius-custom(0 0 10px 10px);

        p {
            opacity: 1;
        }

        i::before {
            content: '\f054';
            line-height: 1.3;
        }
    }

    .project-category-row {
        display: block;
    }

    .project-category-module {
        max-width: 100% !important;
        height: 100% !important;
        width: 100%;
        display: block;
        margin-left: 0 !important;

        &:first-child {
            margin-bottom: 20px !important;
        }

        &::after {
            height: 100% !important;
        }

        .button {
            top: 0 !important;
        }
    }

    .project-module {
        h2 {
            font-size: 18px;
        }

        .image {
            height: 200px;
        }
    }

    .category.container-full-height {
        padding: 0;
    }

    .title-side {
        position: relative;
        width: 100%;
        left: 0;
        top: 0;
        @include transform(none);
    }

    .projecten {
        h1 {
            text-align: left;
        }

        .line-center {
            margin: 10px auto 20px 0;
        }
    }

    .color-square {
        height: 150px;
        margin-bottom: 10px;
    }

    .project-button-container {
        display: block;

        .project-button {
            width: 100%;
            margin: 10px 0;
        }
    }

    .projects-banner-text {
        h1 {
            text-align: left !important;
        }

        hr {
            margin-left: 0;
            margin-right: auto;
        }
    }
}
