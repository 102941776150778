@media (max-width: 575px) {
    .education-container {
        display: block;
    }

    .education {
        margin: 0 0 20px 0;
        padding-bottom: 40px;
    }

    .image-modal {
        padding: 20px;

        img {
            height: auto;
            max-width: 95%;
            max-height: 100%;
        }
    }

    .skills-filter .filter-items .filter-item {
        width: 45%;
        flex: 1 0 45%;

        &:nth-child(3n) {
            margin-right: 10px;
        }

        &:nth-child(2n) {
            margin-right: 0;
        }
    }

    .about-header {
        display: flex;
        flex-direction: column;
    }

    .about-text {
        position: relative;
        top: auto;
        left: auto;
        max-width: 100%;
        order: 1;
        @include transform(translateY(0));
    }

    .custom-alert {
        width: 100%;
        @include border-radius-custom(0);
    }

    .owl-carousel .item {
        border-width: 10px;
    }

    .module-info {
        right: -10px;
        bottom: -10px;
        width: calc(100% + 20px);
        padding: 9px 11.5px;

        span {
            display: none;
        }

        p {
            padding-left: 7.5px;
        }
    }

    .project-module {
        flex: 1 0 100% !important;
        margin: 0 0 20px 0 !important;

        .module-info span {
            display: inline-block;
        }
    }

    .work-title {
        left: -77px;
    }

    .title-side {
        padding: 0 !important;
    }

    .category.container-full-height {
        padding: 100px 0 25px 0;
    }
}

@media (max-width: 375px) {
    header .nav-container {
        width: 80%;
    }

    .work-container h3 {
        font-size: 16px !important;
    }

    .owl-carousel .owl-item,
    .owl-carousel .center .item,
    .owl-carousel {
        height: 40vh;
    }

    .owl-carousel .item {
        height: 25vh;
    }

    .project-module .module-info span {
        display: none;
    }
}
