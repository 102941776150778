@media (max-width: 991px) {
    .skills-filter .filter-items .filter-item {
        width: 30%;
        flex: 1 0 30%;
    }

    .skills-filter .filter-items .filter-item:nth-child(4n) {
        margin-right: 10px;
    }

    .skills-filter .filter-items .filter-item:nth-child(3n) {
        margin-right: 0;
    }

    .home.container-full-height {
        flex-direction: column;
    }

    .header-text {
        width: 270px;
        position: relative;
        left: 0;
        @include transform(unset);
    }

    .owl-dots {
        width: 252px !important;
    }

    .color-square {
        height: 200px;
    }
}
