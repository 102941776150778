@media (hover: hover) and (pointer: fine) {
    .owl-dots .owl-dot:hover span {
        font-weight: bold;
    }

    .module-info:hover {
        width: 300px;
        text-align: left;
        padding-left: 17.5px;

        p,
        span,
        i {
            opacity: 1;
        }

        i::before {
            content: '\f054';
            line-height: 1.3;
        }
    }

    .project-category-module:hover {
        .image {
            height: 100%;
        }

        &::after {
            height: 100%;
        }

        .button {
            top: 0;
        }
    }

    .project-module:hover {
        .module-info {
            width: 265px;
            text-align: left;
            padding-left: 17.5px;

            p,
            i {
                opacity: 1;
            }

            i::before {
                content: '\f054';
                line-height: 1.3;
            }
        }

        &::after {
            height: 100%;
        }

        .title {
            top: 20px;
        }
    }
}

@media (max-width: 991px) and (hover: hover) and (pointer: fine) {
    .project-module:hover .module-info {
        width: calc(100% + 20px);
        @include border-radius-custom(0 0 10px 10px);
    }
}

@media (max-width: 767px) and (hover: hover) and (pointer: fine) {
    .project-module:hover .module-info {
        width: calc(100% + 20px);
    }

    .home-slider .module-info:hover {
        width: calc(100% + 40px);
    }

    .project-category-module:hover .image {
        height: 240px;
    }
}

@media (max-width: 575px) and (hover: hover) and (pointer: fine) {
    .home-slider .module-info:hover {
        width: calc(100% + 20px);
        padding-left: 11.5px;
    }
}
